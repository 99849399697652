@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
        url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-Medium.woff') format('woff'),
        url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
        url('../fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'),
        url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel';
    src: url('../fonts/sequel/Sequel100Wide-45Wide.woff') format('woff'),
        url('../fonts/sequel/Sequel100Wide-45Wide.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel';
    src: url('../fonts/sequel/Sequel100Wide-55Wide.woff') format('woff'),
        url('../fonts/sequel/Sequel100Wide-55Wide.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel';
    src: url('../fonts/sequel/Sequel100Wide-65Wide.woff') format('woff'),
        url('../fonts/sequel/Sequel100Wide-65Wide.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel';
    src: url('../fonts/sequel/Sequel100Wide-75Wide.woff') format('woff'),
        url('../fonts/sequel/Sequel100Wide-75Wide.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel';
    src: url('../fonts/sequel/Sequel100Wide-85Wide.woff') format('woff'),
        url('../fonts/sequel/Sequel100Wide-85Wide.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel';
    src: url('../fonts/sequel/Sequel100Wide-95Wide.woff') format('woff'),
        url('../fonts/sequel/Sequel100Wide-95Wide.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
